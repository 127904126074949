import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import { globalHistory } from '@reach/router'
// import { useBreadcrumb } from 'gatsby-plugin-breadcrumb'
import { TransitionLink } from "@atoms/Link"
// import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import css from "@css/projects/p-breadcrumb-list.module.styl"

function BreadcrumbList({ title })
{
    
    // const { crumbs } = useBreadcrumb({
    //     location: location,
    //     crumbLabel: title,
    //     crumbSeparator: ' / ',
    // })
    const labels = {
        'about': '大泉グループとは',
        'info': '企業情報',
        'CSR活動': '取り組み',
        'news' : 'お知らせ',
        'recruit': '採用情報',
        'terms': '利用規約',
        'privacy-policy': 'プライバシーポリシー',
    }
    const { location } = globalHistory
    let array = location.pathname.split('/')
    let newArray = array.filter(n => n !== "")
    let savePath = ""
    return (
        <div className={css.container}>
            <TransitionLink to='/'>Top</TransitionLink>
            {newArray && newArray.map( (path, i) => {
                savePath += `/${path}/`
                if( newArray.length - 1 > i){
                    return (
                        <TransitionLink key={i} to={`${savePath}`}>{ labels[path] || path }</TransitionLink>
                    )
                } else {
                    return(
                        <span key={i}>{labels[path] || title || path}</span>
                    )
                }
            })}
        </div>
    )
}

export { BreadcrumbList }