import React from "react"
// import { TransitionLink } from "gatsby"
import { graphql } from "gatsby"
import Seo from "@atoms/Seo"
import Hero from "@blocks/page/Hero"
import { BreadcrumbList } from "@projects/BreadcrumbList"
// import { Section, Title, Paragraph, Column } from "@blocks/page/Section"
import NewsArticle from "@blocks/page/NewsArticle"
import { ContentFooter } from "@projects/ContentFooter"
import css from "@css/projects/page/p-content.module.styl"
// import Prism from '@components/Prism'


function RecruitPageTemplate({data, path})
{
	const { slug, newsId, date, title, content, image, is_private } = data.news

	// console.log(">>>",data)
	let heroImage = image ? image.url : data.main01
    return (
		<>
			<Seo subtitle={title} description={''} image={image ? image.url: false}
				robot={is_private ? true : false}
			/>
            <Hero
				image={heroImage}
                label={'NEWS'}
                title1={date}
				title2={is_private ? `【限定公開】${title}`: title}
                links={[
                ]}
            />
            <div className={css.container}>
                <div className={css.inner}>
					<BreadcrumbList title={title}/>
					<NewsArticle data={data.news}/>
                </div>
            </div>
			<ContentFooter
				links={[
					{
						label: 'ニュース一覧',
						to: '/news/'
					}
				]}
			/>
        </>
    )
}

export const pageQuery = graphql`
	query NewsBySlug($id: String!) {
		site {
			siteMetadata {
				title
				author
			}
		}
		news: microcmsNews(
            id: { eq: $id }
        ) {
            content
			date(formatString: "YYYY.MM.DD")
			title
			slug
			newsId
			is_private
			image {
				url
			}
        }
		main01 : file(relativePath: { eq: "pageInfo__img__01@2x.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1440) {
					...GatsbyImageSharpFluid
				}
			}
		}

		dotImg : file(relativePath: { eq: "hero__img__dot.png" }) {
			childImageSharp { fixed( width: 2, height: 2) {
					...GatsbyImageSharpFixed
				}
			}
		}
	}
`
export default RecruitPageTemplate
