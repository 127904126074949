import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import * as Anim from "@atoms/animation/Animation"
import { YoutubeBackground } from '@projects/VideoBackground'
import css from "@css/projects/page/p-hero.module.styl"
import indexCss from "@css/projects/index/p-hero.module.styl"

export default function HeroA({image, label,title1,title2,links, videoId})
{
    const q = useStaticQuery(graphql`
        query {
            dot : file(relativePath: { eq: "hero__img__dot.png" }) {
                childImageSharp { fixed( width: 2, height: 2) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const Bg = ({children, image})=>{
        if( image && image.match && image.match(/http/g) ){
            return(
                <div className={css.bgUrl} style={{
                    backgroundImage: `url(${image})`,
                }}>
                    {children}
                </div>
            )
        }
        return(
            <>
                { image ?
                    <BackgroundFluidImage className={css.bg} data={image}>{children}</BackgroundFluidImage>
                    :
                    <div className={css.bgDefalut}>{children}</div>
                }
            </>
        )
    }
    return(
        <Anim.ToggleClass delay={1.45} type="Hero">
            <div className={css.container} id="hero">
                {
                    videoId ?
                    <div className={indexCss.container}>
                        <div className={indexCss.bg}>
                            <YoutubeBackground videoId={videoId} />
                        </div>
                    </div>
                    :
                    <Bg image={image}/>
                }
                <div className={css.overlay} style={{ backgroundImage: `url(${q.dot.childImageSharp.fixed.base64})` }} />
                <div className={css.inner}>
                    <div className={css.content}>
                        <p className={css.label}>
                            {label}
                        </p>
                        <h1 className={css.title1}>
                            {title1}
                        </h1>
                        <h2 className={css.title2}>
                            {title2}
                        </h2>
                        <div className={css.links}>
                            { links && links.map(({label,to, scroll},i)=>{
                                return(
                                    <TransitionLink key={i} className={css.button} to={to} scroll={scroll}>{label}</TransitionLink>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Anim.ToggleClass>
    )
}
