import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { globalHistory } from '@reach/router'
// import { useBreadcrumb } from 'gatsby-plugin-breadcrumb'
import { TransitionLink } from "@atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import css from "@css/projects/p-content-footer.module.styl"
// import TopSvg from "@images/common__btn__top.svg"
function ContentFooter({ banner, links })
{
    const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "indexHero__img__main01@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const bannerDom = () => {
        if( !banner ){ return }
        const { img, label, title, to} = banner
        return (
            <BackgroundFluidImage data={img} className={css.pContentFooter__banner}>
                <div className={css.pContentFooter__banner__inner}>
                    <div>
                        <div className={css.pContentFooter__banner__label}>{label}</div>
                        <div className={css.pContentFooter__banner__title}>{title}</div>
                        <TransitionLink to={to} className={css.pContentFooter__banner__button}>詳しく</TransitionLink>
                    </div>
                </div>
            </BackgroundFluidImage>
        )
    }

    return (
        <div className={css.pContentFooter}>
            <div className={css.pContentFooter__inner}>
                { bannerDom() }
                <div className={css.pContentFooter__links}>
                    { links && 
                        links.map(({ label, to }, i) => {
                            return (
                                <TransitionLink to={to} className={css.pContentFooter__topButton} key={i}>
                                    {label}
                                </TransitionLink>
                            )
                        })
                    }
                    <div className={css.pContentFooter__links__section}>
                        <TransitionLink to="/" className={css.pContentFooter__homeButton}>
                            トップページに戻る
                        </TransitionLink>
                        <TransitionLink scroll='#root-container' className={css.pContentFooter__topButton}>
                            先頭に戻る
                        </TransitionLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ContentFooter }