import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import css from "@css/projects/page/p-news-article.module.styl"

const unescapeHtml = (target) => {
	if (typeof target !== 'string') return target;

	var patterns = {
		'&lt;'   : '<',
		'&gt;'   : '>',
		'&amp;'  : '&',
		'&quot;' : '"',
		'&#x27;' : '\'',
		'&#x60;' : '`'
	};

	return target.replace(/&(lt|gt|amp|quot|#x27|#x60);/g, function(match) {
		return patterns[match];
	});
};

export default function NewsArticle({data})
{
    const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "indexContent__img__main01@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            main02 : file(relativePath: { eq: "indexContent__img__main02@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            main03 : file(relativePath: { eq: "indexContent__img__main03@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    console.log('news page',data)
    const { title, date, content } = data
    
    return(
        <article className={css.container}>
            <div className={css.inner}>
                <p className={css.info}>
                    <span className={css.date}>{date}</span>
                    <span className={css.category}>NEWS</span>
                </p>
                <h4 className={css.title}>{title}</h4>
                <div className={css.content} dangerouslySetInnerHTML={{__html: unescapeHtml(content).replace(/\>\<br\>/g, '>')}}/>
                {/* <div className={css.content}>{content}</div> */}
            </div>
        </article>
    )
}
